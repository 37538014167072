body {
    margin: 0;
    padding: 0;
    font-weight: 500;
    letter-spacing: 1px;
    transition: all 0.2s ease-in-out 0s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-family: "Oxygen", sans-serif;
}
