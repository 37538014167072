.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  min-height: 50px;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us {
  text-align: center;
}

a {
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .contact-us {
    margin-top: 10px;
    text-align: left;
  }
  
  .fixed-footer {
    flex-direction: column; 
    padding: 10px;
  }
}
