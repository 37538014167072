:root {
  --text-color: #1c1e21;
  --green-color: #56B48C ;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff; 
  color: var(--text-color);
  z-index: 1000;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav ul li {
  margin-right: 30px;
}

nav a {
  text-decoration: none;
  transition: color 0.3s ease;
  color: var(--text-color);
  box-shadow: none;
  font-size: 1.0625rem;
}

nav a:hover {
  color: var(--green-color);
}

.profile-icon {
  position: relative;
  margin-right: 1rem;
}

.user-icon {
  width: 30px; 
  height: 30px; 
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
}

.profile-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 10px; 
  background-color: #fff;
  border-radius: 10px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: max-content;
}

.profile-icon:hover .profile-dropdown {
  display: block;
}

.profile-dropdown div {
  padding: 10px;
  color: #2f4858; 
  border-radius: 10px; 
}

.profile-dropdown div:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.logout {
  color: #ff3b3b;
}

nav {
  margin-right: auto;
  margin-left: 3rem;
}

.user-email {
  font-weight:100;
  font-size: 12px;
}

.branding {
  margin-left: 1rem;
}

.menu-icon {
  display: none;
}

.logout:hover {
  color: #c62828; 
}


@media (max-width: 768px) {
  .branding img {
    height: 30px; 
    width: auto; 
  }

  nav ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #fff;
    width: 100%;
    display: none;
  }

  nav ul.show-link {
    display: flex; 
  }

  nav ul li {
    margin: 0; 
    border-bottom: 1px solid #eee; 
    text-align: center; 
    padding: 15px 0; 
  }

  .profile-icon, .branding {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .user-icon {
    width: 25px;
    height: 25px;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    align-content: center;
  }

  .menu-parent {
    display: flex;
  }
}
