/* login.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

:root {
  --primary-color: #3f51b5;
  --secondary-color: #ff5722;
  --text-color: #fff;
  --green-background:#56B48C ;
}

.login-container {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}

.top-section {
  display: flex;
  background-color: var(--green-background);
  padding: 25px;
  height: 40%;
  color: var(--text-color);
  align-items: center;
  justify-content: center;
}

.login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header-titile {
  font-weight: 600;
  font-size:3rem;
  margin: auto;
}

.description {
  font-size: 1.1rem;

}

.signin-btn {
  border: none;
  padding: 12px 24px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px;
}

.signin-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.bottom-section {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.section {
  width: calc(30% - 20px);
  margin-right: 10px; 
  margin-bottom: 20px; 
  text-align: center; 
}

.section img {
  width: 150px; 
  height: 150px; 
}

.section h3 {
  margin-top: 20px;
}

.section p {
  margin-top: 10px;
  word-break: break-word; 
  text-align: justify;
  word-spacing: normal;
  letter-spacing: normal;
}

/* Media Query */
@media screen and (max-width: 768px) {
  .login-container {
    justify-content: center;
    align-items: center;
  }

  .login-content {
    text-align: center;
  }

  .login-header {
    margin-bottom: 20px; 
  }

  .signin-btn {
    font-size: 1rem;
    padding: 12px 24px;
    margin-top: 20px;
  }

  .bottom-section {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: calc(100% - 40px); 
    margin-right: 0; 
  }
}
