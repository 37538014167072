
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

:root {
  --primary-color: #3f51b5;
  --secondary-color: #ccc;
  --text-color: #fff;
  --green-background: #56B48C ;
}

.home-container {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  text-align: center;
}

.home-content {
  width: 100%;
}

.home-top-section {
  display: flex;
  background-color: var(--green-background);
  padding: 25px;
  height: 40%;
  color: var(--text-color);
  align-items: center;
  justify-content: center;
}

.home-title {
  font-weight: 600;
  margin: auto;
  font-size: 1.5rem;
  text-align: center;
}

.description {
  font-size: 1.1rem;

}

.home-bottom-section {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.home-section {
  width: calc(30% - 20px);
  margin-right: 10px; 
  margin-bottom: 20px; 
  text-align: center; 
}

.home-section img {
  width: 75px; 
  height: 150px; 
}

.home-section h3 {
  margin-top: 20px;
}

.home-section p {
  margin-top: 10px;
  word-break: break-word; 
  text-align: justify;
  word-spacing: normal;
  letter-spacing: normal;
}

.title {
  display: block;
}

form {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.input-field {
  padding: 0 20px;
  border: 2px solid var(--secondary-color);
  border-radius: 25px;
  color: var(--primary-color);
  background-color: #fff;
  outline: none;
  transition: border-color 0.3s;
  height: 48px; 
  width: 40%;
}

.input-field:focus {
  border-color: var(--primary-color);
}

.shorten-btn {
  height: 48px; 
  background-color: #fff;
  border: none;
  border-radius: 25px;
  padding: 0 15px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-left: 10px; 
  white-space: nowrap; 
}

.shorten-btn:hover {
  transform: translateY(-2px);
}

.copy-btn {
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left:5px;
}

.result-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.shortened-url-container {
  background-color: #dcffe4;
  color: #033500;
  text-align: center;
  padding: 12px 15px;
  border-radius: 12px;
  width: fit-content;
}

.shortened-url {
  flex: 1;
  word-break: break-all;
  color: #007bff;
  text-decoration: none;
}

.copy-btn:hover, .shorten-btn:hover {
  cursor: pointer;
}


@media screen and (max-width: 768px) {
  .home-container {
    justify-content: center;
    align-items: center;
  }

  .home-content {
    text-align: center;
  }

  .login-header {
    margin-bottom: 20px; 
  }

  .home-bottom-section {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }

  .home-section {
    width: calc(100% - 40px); 
    margin-right: 0; 
  }

  .input-field, .shorten-btn {
    height: 40px; 
    font-size: 0.9rem; 
  }

  form {
    flex-direction: column; 
    align-items: stretch; 
  }

  .input-field {
    margin-bottom: 10px; 
    width: auto;
  }

  .shorten-btn {
    width: 100%; 
    margin-left: 0; 
  }

  .shortened-url-container {
    width: 100%;
  }
}



